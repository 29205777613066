import { useStorefront, useRequest } from "@kega/sps-core";

const useInvoiceAttachment = () => {
    const { api: { occcustomer } } = useStorefront();

    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();

    const getInvoiceAttachment = async ({ number, baseSiteId, country, currency }) => {
        try {
            setRequest();

            const result = await occcustomer.getInvoiceAttachment({ number, baseSiteId, country, currency })

            setSuccess(result);

            return result;

        } catch (error) {
            console.log('[getInvoiceAttachment]', error);
            setError(error);

            return null;
        }
    };

    return {
        result: data,
        loading,
        error,
        getInvoiceAttachment
    };
};

export default useInvoiceAttachment;
