import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart, useCartId, useConfig, useCreateCart, useLogin } from "@kega/sps-core";
import { Form } from "@kega/sps-elements";
import qs from "qs";

import Input from "../form/Input";
import Button from "../buttons/Button";

import { t } from "../../lib/translations";

import useTranslatePath from "../../hooks/useTranslatePath";
import useTagManager from "../../hooks/useTagManager";
import useFetchWishlist from "../../hooks/wishlist/useFetchWishlist";
import useCustomerCountry from "../../hooks/customer/useCustomerCountry";
import useGeoIp from "../../hooks/useGeoIp";

import classes from "./Login.module.css";

const Login = () => {
    const config = useConfig();
    const navigate = useNavigate();

    const { search } = useLocation();
    const { fetchCart } = useCart();
    const { createCart } = useCreateCart();
    const getCartId = useCartId();
    const { getPath } = useTranslatePath();
    const { dataLayer } = useTagManager();
    const { fetchWishlist } = useFetchWishlist();
    const { setCountry } = useCustomerCountry();
    const { userCountry, getGeoIp } = useGeoIp();

    const [loggingin, setLoggingIn] = useState(false);
    const { login, error: loginError } = useLogin();

    const authentication = config.get('authentication');

    useEffect(() => {
        getGeoIp();
    }, []);

    const onSubmit = async ({ valid, values, resetForm }) => {

        if (valid) {

            try {
                setLoggingIn(true);

                const token = await login({
                    username: values.username.toLowerCase(),
                    password: values.password,
                    grant_type: authentication.grant_type,
                    client_id: authentication.client_id,
                    client_secret: authentication.client_secret
                });

                if (token) {
                    setCountry(userCountry);
                    const oldCartId = getCartId();

                    const currentCart = await fetchCart('current');

                    if (oldCartId) {
                        // Merge guest cart with customer cart, then fetch new merged cart
                        await createCart({
                            oldCartId: oldCartId,
                            mergeCartId: currentCart?.guid,
                            userId: 'current'
                        });

                        await fetchCart('current');
                    }

                    if (!currentCart.cartId) {
                        await createCart({
                            userId: 'current'
                        });
                        
                        await fetchCart('current');
                    }

                    await fetchWishlist();

                    resetForm();

                    setLoggingIn(false);

                    try {
                        dataLayer.push({
                            'event': 'login',
                            'method': 'normal'
                        });
                    } catch (error) {
                        //
                    }

                    const params = qs.parse(search.replace('?', ''));
                    if (params?.ref) {
                        navigate({
                            pathname: params?.ref
                        });
                    } else {
                        navigate({
                            pathname: getPath('/account/overview')
                        });
                    }

                } else {
                    setLoggingIn(false);
                }
            } catch (error) { /* Empty */ }
        }

    };

    return (
        <div>
            {
                loginError
                &&
                <span className={classes.error_message}>
                    {t('account.login.login_error')}
                    <Link to={getPath('/account/forgotpassword')}>{t('account.login.lost_password')}</Link>
                </span>
            }

            <p>{ t('account.login.text') }</p>

            <Form onSubmit={onSubmit}>

                <Input
                    name="username"
                    type="email"
                    label={t('form.username_label')}
                    required
                    className={classes.input}
                    errorMessage={t('form.error_not_empty')} />

                <div className={classes.password_container}>
                    <Input
                        name="password"
                        type="password"
                        label={t('form.password_label')}
                        required
                        className={classes.input}
                        errorMessage={t('form.error_not_empty')} />
                    <Link className={classes.forgot_password} to={getPath('/account/forgotpassword')}>{t('account.login.lost_password')}</Link>
                </div>
                <Button
                    type="submit"
                    variant="secondary"
                    className={classes.button}
                    loading={loggingin}
                >
                    {t('account.login.btn_login')}
                </Button>

            </Form>

        </div>
    );
}

export default Login;
