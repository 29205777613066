import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ProductImage } from '@kega/sps-components';

import useMediaQuery from '../../hooks/useMediaQuery';

import BlueBillyWigVideo from '../bluebillywigvideo/BlueBillyWigVideo';
import { ChevronZoomIcon, YoutubeIcon } from '../icons';
import YoutubeEmbed from '../youtubeembed/YoutubeEmbed';

import classNames from 'classnames';
import classes from './ZoomImage.module.css';

const ZoomImage = ({ images, thumbnails = true, visibleThumbs = 6 }, ref) => {
    const [ open, setOpen ] = useState(false);
    const [ active, setActive ] = useState(0);
    const [thumbnailStart, setThumbnailStart] = useState(0);
    const { match } = useMediaQuery('(max-width: 768px)');

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [imageHeight, setImageHeight] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showVideo, setShowVIdeo] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleImageLoad = (e) => {
        setImageHeight(e.target.offsetHeight);
        setImageLoaded(true);
    };

    const initialPositionY = imageHeight ? (viewportHeight - imageHeight) / 2 : 100;

    useImperativeHandle(ref, () => ({
        openOnIndex: (index) => { 
            setActive(index);
            setOpen(true);
        }
    }));

    const closeZoom = () => {
        setOpen(false);
        setImageLoaded(false);
    }

    if (!open) { return null; }

    const { zoom, alt, video_url } = images[active];

    const nextThumbnail = () => {
        if (thumbnailStart + 1 < images.length) {
            setThumbnailStart(thumbnailStart + 1);
        }
    }

    const prevThumbnail = () => {
        if (thumbnailStart > 0) {
            setThumbnailStart(thumbnailStart - 1);
        }
    }

    const next = () => {
        const nextIndex = (active + 1) % images.length;
        setActive(nextIndex);
    
        if (nextIndex >= thumbnailStart + visibleThumbs) {
            nextThumbnail();
        }
    }
        
    const prev = () => {
        const prevIndex = (active - 1 + images.length) % images.length;
        setActive(prevIndex);
    
        if (prevIndex < thumbnailStart) {
            prevThumbnail();
        }
    }

    return (
        <>
            <div className={classes.root}  >
                <div onClick={closeZoom} className={classes.close}></div>

                <TransformWrapper key={active} centerZoomedOut={true} initialScale={1} initialPositionY={!match ? (imageLoaded ? initialPositionY : 0) : 200} >
                    {({ zoomIn, zoomOut }) => {
                        return (
                            <>
                                {(active > 0) && 
                                    <button className={classNames(classes.button, classes.prev)} onClick={prev}>
                                        <ChevronZoomIcon direction='bottom' size={50} />
                                    </button>
                                }
                                {
                                    ((active + 1) < images.length) &&
                                        <button className={classNames(classes.button, classes.next)} onClick={next}>
                                            <ChevronZoomIcon size={50} />
                                        </button>
                                }
                                <div className={classes.tools}>
                                    <div onClick={() => zoomIn()} className={classes.plus}></div>
                                    <div onClick={() => zoomOut()} className={classes.min}></div>
                                </div>
                                <TransformComponent wrapperClass={classes.transform_wrapper} >

                                    {
                                        !video_url ?
                                            <img src={zoom} alt={alt} onLoad={handleImageLoad} />
                                            :
                                            (
                                                video_url?.includes('bbvm') ?
                                                    <BlueBillyWigVideo videoUrl={video_url} classname={classes.bbw} />
                                                    :
                                                    <>
                                                        <div id="image" className={classes.image}>
                                                            <div id="video-image" className={classNames(classes.video_image, showVideo ? classes.video_active : null)} onClick={() => setShowVIdeo(true)}>
                                                                <img id="img" src={zoom} alt={alt} onLoad={handleImageLoad} />
                                                                <div className={classes.youtube_overlay}>
                                                                    <YoutubeIcon size={150} />
                                                                </div>
                                                            </div>
                                                            <YoutubeEmbed id="video" classname={classes.video} videoUrl={video_url} />
                                                        </div>
                                                    </>
                                            )
                                    }

                                </TransformComponent>
                            </>
                        )}}
                </TransformWrapper>
            </div>
            {thumbnails && (
                <div className={classes.thumbnails_zoom}>
                    {
                        (thumbnailStart + visibleThumbs) < images.length && 
                            <button className={classNames(classes.button_thumbs, classes.next_thumbs)} onClick={nextThumbnail}>
                                <ChevronZoomIcon direction='right' size={30} />
                            </button>
                    }
                    {
                        thumbnailStart > 0 && 
                            <button className={classNames(classes.button_thumbs, classes.prev_thumbs)} onClick={prevThumbnail}>
                                <ChevronZoomIcon direction='left' size={30} />
                            </button>
                    }
                    {images.slice(thumbnailStart, thumbnailStart + visibleThumbs).map((item, index) => (
                        <div className={classNames(classes.inner_zoom_thumbnails, index + thumbnailStart === active ? classes.active : null)} key={`thumbnail-${index}`} onClick={() => setActive(index + thumbnailStart)}>
                            {
                                !!item.video_url &&
                                <div className={classes.youtube_overlay}>
                                    <YoutubeIcon size={25} />
                                </div>
                            }
                            <ProductImage
                                src={item.thumbnail}
                                alt={item.alt}
                                width={150}
                                height={150}
                                loading={(index === active || index === active + 1 ? "eager" : "lazy")}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>

    )
}

export default forwardRef(ZoomImage);