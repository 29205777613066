import useCategoryHierarchy from "../../../hooks/catalog/useCategoryHierarchy";

import { t } from "../../../lib/translations";

import Category from "./category/Category";

import classes from "../ProductList.module.css";

const CategoryHierarchy = ({ categoryId }) => {
    const { categoryHierarchy } = useCategoryHierarchy({ categoryId });

    if (!categoryHierarchy?.subcategories?.length) {
        return null;
    }

    return (
        <div>
            <span className={classes.categories_title}>{t('productlist.filter.categories')}</span>
            {
                categoryHierarchy.subcategories.map((category, index) => <Category {...category} key={index}/>)
            }
        </div>
    )
};

export default CategoryHierarchy;
