import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = [];

export default createReducer(initialState, {

    [actions.addProduct]: (state, action) => {
        const { payload } = action;
        const packingInfo = `${payload?.packagingUnit || ''} ${payload?.numbercontentunits || ''} ${payload?.contentUnit || ''}`.trim();

        const newData = {
            code: payload.code,
            baseCode: payload.code,
            name: payload.name,
            images: payload.images,
            Merk: payload.Merk ?? payload.manufacturer,
            stock: payload.stock,
            Prijs: payload.Prijs ?? payload.price,
            priceRange: payload.priceRange,
            descriptionHs: payload.descriptionHs ?? '',
            formattedName: payload.formattedName || payload.name,
            orderableAums: payload.assortmentMinQuantity ?? [],
            url: payload.url, 
            nonfoodOrderUnitDescription: packingInfo || payload?.nonfoodOrderUnitDescription || payload?.nonfoodOrderUnitLabel,
            minQuantity: payload.assortmentMinQuantity || 1,
        }

        const index = state.findIndex(({ code }) => {
            return payload.code === code;
        });

        if (index === -1) {
            state.unshift(newData); // Insert current item from payload

            if (state.length > 12) {
                state.pop();
            }

        } else {
            state.splice(index, 1); // Remove current item
            state.splice(0, 0, newData); // Re-insert current item from payload
        }
    }

});
