import { useState } from "react";
import { useCustomer } from "@kega/sps-core";

import CustomerCountryNotice from "./CustomerCountryNotice";
import GuestCountryNotice from "./GuestCountryNotice";

const CountryNotice = () => {
    const customer = useCustomer();
    const { authenticated } = customer;

    const [justLoggedOut, setJustLoggedOut] = useState(false);

    const handleUserLoggedOut = () => {
        setJustLoggedOut(true);
        setTimeout(() => setJustLoggedOut(false), 100); 
    };

    return (
        authenticated 
            ? <CustomerCountryNotice onUserLoggedOut={handleUserLoggedOut} /> 
            : <GuestCountryNotice preventPopup={justLoggedOut} />
    );

}

export default CountryNotice;