import { useMemo } from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import useAlgoliaProductData from "../../../hooks/useAlgoliaProductData";
import useTranslatePath from '../../../hooks/useTranslatePath';

import ProductTile from '../../../components/product/ProductTile';
import ProductTileSkeleton from '../../../components/product/ProductTileSkeleton';

import classes from './ProductGrid.module.css';

const ProductGrid = ({ hits, searchResults }) => {

    const { parseProduct } = useAlgoliaProductData();
    const { getPath } = useTranslatePath();
    const config = useConfig();
    const { pagination: { defaultPerPage } } = config.get('productlist');

    const { nbHits } = searchResults || {};
    

    const skeleton = useMemo(() => {
        const items = [];
        for (let i = 0; i < defaultPerPage; i++) {
            items.push(<ProductTileSkeleton key={String('tile'+i)} />);
        }
        return items;
    }, [defaultPerPage]);

    
    // Loading
    if (nbHits === undefined) {
        return (
            <div className={classes.root}>
                { skeleton }
            </div>
        )
    }

    return (
        <div className={classes.root}>
            {
                hits.length === 0
                    ?
                    <div>{t('productlist.notfound')}</div>
                    :
                    hits.map((hit, index) => {
                        const product = parseProduct(hit);

                        return (
                            <>
                                {
                                    (index % 6 === 0 && index !== 0) ?
                                        <>
                                            <div className={classes.row}>
                                                <BuilderSectionLazy model="plp-usp" url={getPath('/')} />
                                            </div>
                                            <ProductTile key={hit.objectID} product={product} index={index} />
                                        </> :
                                        <ProductTile key={hit.objectID} product={product} index={index} />
                                }
                            </>
                        );
                    })
            }
        </div>
    )
}

export default connectHits(connectStateResults(ProductGrid));