export default ({ client, tokens }) => {

    const api = {};

    api.getCategoryHierarchy = async ({ rootCategoryId }) => {

        const { token='' } = await tokens.get('public');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            client.get(`/categories/hanosProductCatalog/Online/categoryHierarchy/${rootCategoryId}?fields=DEFAULT`, null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
          
        });

    }

    return api;
};