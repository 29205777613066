import { useMemo, useRef, useEffect, Suspense, lazy, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProductDescription } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';

import { t } from '../../lib/translations';
import { Col, Row, Container } from '../../components/grid';

import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigationPath from '../../hooks/useNavigationPath';
import useTagManager from '../../hooks/useTagManager';
import useItemCategories from '../../hooks/useItemCategories';
import useScarabQueue from '../../hooks/useScarabQueue';
import useProductReviewSummary from "../../hooks/trustpilot/useProductReviewSummary";
import useProductReviews from "../../hooks/trustpilot/useProductReviews";
import useInViewport from "../../hooks/useInViewport";

import AnchorScrollItem from "../../components/product/anchors/AnchorScrollItem";
import MediaSlider from '../../components/mediaslider/MediaSlider';
import ZoomImage from '../../components/zoomimage/ZoomImage';
import RecentlyViewed from '../../components/recentlyviewed/RecentlyViewed';
import ReadMore from '../../components/readmore/ReadMore';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import ProductLabels from '../../components/product/labels/ProductLabels';
import Average from '../../components/reviews/average/Average';

import ProductInfo from './info/ProductInfo';
import Anchors from './anchors/Anchors';
import Classifications from './classifications/Classifications';
import StructuredData from './structureddata/StructuredData';
import Reviews from "./reviews/Reviews";
import ProductInfoModal from './infomodal/ProductInfoModal';

const ReleatedProducts = lazy(() => import('./relatedproducts/ReleatedProducts'));
const FrequentlyBoughtTogether = lazy(() => import( '../cart/frequentlyboughttogether/FrequentlyBoughtTogether'));

import classes from "./ProductDetails.module.css";

const ProductDetails = ({ product }) => {
    const {
        code,
        name,
        formattedName,
        Merk,
        mainCategory,
        categoryPath,
        videos = [],
        bbwVideos = [],
        descriptionHs = '',
        informationHeaderHs = '',
        informationHs = '',
        additionalInformationHs = '',
        additionalInformationHeaderHs = '',
        objectID = null,
        trustPilotProductOneStar,
        trustPilotProductTwoStars,
        trustPilotProductThreeStars,
        trustPilotProductFourStars,
        trustPilotProductFiveStars,
        trustPilotProductReviewAverage,
        trustPilotProductReviewCount,
        price
    } = product;

    const location = useLocation();

    let { images = [] } = product;

    const { ecommerceEvent } = useTagManager();
    const { match: desktop } = useMediaQuery('(min-width: 768px)');
    const { pushScarabProduct } = useScarabQueue();
    const { summary, getReviewSummary } = useProductReviewSummary();
    const { reviews, getReviews } = useProductReviews();
    const shouldLoadReviewSummary = !trustPilotProductReviewAverage;

    const reviewSummary = !shouldLoadReviewSummary
        ? {
            "numberOfReviews": {
                "total": trustPilotProductReviewCount,
                "oneStar": trustPilotProductOneStar,
                "twoStars": trustPilotProductTwoStars,
                "threeStars": trustPilotProductThreeStars,
                "fourStars": trustPilotProductFourStars,
                "fiveStars": trustPilotProductFiveStars
            },
            "starsAverage": trustPilotProductReviewAverage
        }
        : summary;

    const ref = useRef(null);
    const zoomImageRef = useRef(0);
    const tagManagerData = useRef({ code, name, Merk, price }).current;

    const { isIntersecting } = useInViewport(ref, true);

    const config = useConfig();

    const path = useNavigationPath('HS_AssortmentNavBarComponent', mainCategory ? mainCategory?.code : '');

    const [relatedNumber, setRelatedNumber] = useState();
    const [showModal, setShowModal] = useState(false);

    const handleRelated = (num) => {
        setRelatedNumber(num);
    };

    const itemCategories = useItemCategories(categoryPath);


    useEffect(() => {
        setShowModal(true);

        return () => {
            setShowModal(false);
        }
    }, []);

    useEffect(() => {
        if (product?.code) {
            pushScarabProduct(product.code);
            getReviews(product.code);

            if (shouldLoadReviewSummary) {
                getReviewSummary(product.code);
            }
        }
    }, [product?.code, location.pathname]);

    useEffect(() => {
        try {
            const { code, Merk, price } = tagManagerData;

            ecommerceEvent('view_item', {
                value: price?.value,
                currency: config.get('currency'),
                items:[{
                    item_id: code,
                    item_name: formattedName,
                    item_brand: Merk ?? product?.manufacturer,
                    price: price?.value,
                    item_category: itemCategories[0],
                    item_category2: itemCategories[1],
                    item_category3: itemCategories[2],
                    item_category4: itemCategories[3],
                    item_category5: itemCategories[4],
                }]
            });
        } catch (error) {
            console.log(error)
        }

    }, [tagManagerData,itemCategories])

    const formattetImages = useMemo(() => {
        let newImages = [];

        if (videos.length > 0) {
            images = images.concat(videos);
        }

        if (bbwVideos.length > 0) {
            images = images.concat(bbwVideos);
        }

        if (images.length === 0) {

            newImages = [
                {
                    alt: formattedName,
                    image: '/images/placeholder.png',
                    thumbnail: '/images/placeholder.png',
                    zoom: '/images/placeholder.png',
                    video_url: null,
                }
            ]

        } else {
            images.forEach(({ galleryIndex, format, imageType, url, preview }) => {
                if (imageType === 'GALLERY') {
                    if (newImages[galleryIndex] === undefined) { newImages[galleryIndex] = { alt: formattedName } }

                    if (format === 'product') {
                        //newImages[galleryIndex].image = url; Use large image for image slider normal image to small
                    } else if (format === 'thumbnail') {
                        newImages[galleryIndex].thumbnail = url;
                    } else if (format === 'store') {
                        newImages[galleryIndex].zoom = url;
                        newImages[galleryIndex].image = url;
                    }
                }

                if (preview?.format === 'RAW') {
                    let videoIndex = newImages.length;
                    newImages[videoIndex] = { alt: formattedName }
                    newImages[videoIndex].zoom = preview.url;
                    newImages[videoIndex].thumbnail = preview.url;
                    newImages[videoIndex].image = preview.url;
                    newImages[videoIndex].video_url = url.replace('watch?v=', 'embed/');
                }

                if (url?.includes('bbvm')) {
                    let videoIndex = newImages.length;

                    newImages[videoIndex] = { alt: formattedName }
                    newImages[videoIndex].zoom = preview?.url;
                    newImages[videoIndex].thumbnail = newImages[0].thumbnail;
                    newImages[videoIndex].image = newImages[0].thumbnail;
                    newImages[videoIndex].video_url = url;
                }
            });
        }

        return newImages;
    }, [images]);

    const onImageClick = (index, image) => {
        if (zoomImageRef) {
            zoomImageRef.current.openOnIndex(index);
        }
    }

    return (
        <>
            <Container xl margins g-xs={2} g-md={3}>
                <Row>

                    <Col md={12}>
                        <Breadcrumb prefix={[{ name: 'Home', url_key: '/' }]} paths={path.slice(1)} /> {/* remove non food category */}
                    </Col>

                    <Col md={12} className={classes.heading}>
                        <span>{Merk ?? product.manufacturer}</span>
                        <h1>{formattedName}</h1>
                        <AnchorScrollItem scrollToId="reviews" label={<Average average={reviewSummary?.starsAverage} total={reviewSummary?.numberOfReviews.total} className={!reviewSummary?.numberOfReviews.total ? classes.average_hidden : ''}/>}/>
                    </Col>

                    <Col md={7}>

                        <div className={classes.gallery}>

                            <ProductLabels product={product} />

                            <MediaSlider
                                images={formattetImages}
                                imageWidth={460}
                                imageHeight={460}
                                onImageClick={onImageClick}
                            />

                            <ZoomImage ref={zoomImageRef} images={formattetImages} />
                        </div>

                        {!desktop && <ProductInfo product={product} ref={ref} />}
                    
                        <div className={classes.anchors}>
                            <Anchors relatedNumber={relatedNumber} product={product} reviewSummary={reviewSummary} />
                        </div>

                        <ReadMore height={descriptionHs ? 200 : null} className={classes.description}>
                            {
                                !!descriptionHs
                                &&
                                <div className={classes.description} id="description">
                                    <h2>{t('productdetails.description_label')}</h2>
                                    <ProductDescription content={descriptionHs} html={true} />
                                </div>
                            }
                            {
                                !!informationHs
                                &&
                                <div className={classes.description} id="information-description">
                                    {
                                        !!informationHeaderHs && <h2>{informationHeaderHs}</h2>
                                    }
                                    <ProductDescription content={informationHs} html={true} />
                                </div>
                            }

                            {
                                !!additionalInformationHs
                                &&
                                <div className={classes.description} id="additional-description">
                                    {
                                        !!additionalInformationHeaderHs && <h2>{additionalInformationHeaderHs}</h2>
                                    }
                                    <ProductDescription content={additionalInformationHs} html={true} />
                                </div>
                            }
                        </ReadMore>

                        <Classifications product={product} />

                    </Col>

                    <Col md={5}>

                        {desktop && <ProductInfo product={product} ref={ref} />}

                    </Col>
                </Row>
            </Container>

            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    {
                        objectID
                        &&
                        <Col md={12} >
                            <Suspense fallback={<></>}>
                                <ReleatedProducts handleRelated={handleRelated} code={objectID} />
                            </Suspense>
                        </Col>
                    }
                </Row>
            </Container>

            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    {
                        objectID
                        &&
                        <Col md={12} >
                            <Suspense fallback={<></>}>
                                <FrequentlyBoughtTogether code={objectID}/>
                            </Suspense>
                        </Col>
                    }
                </Row>
            </Container>

            {(showModal && !isIntersecting) && <ProductInfoModal product={product} images={images} />}

            <Reviews summary={reviewSummary} reviews={reviews}/>

            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    <Col md={12}>
                        <RecentlyViewed product={product} />
                    </Col>
                </Row>
            </Container>

            <StructuredData product={product} />
        </>
    )
}

export default ProductDetails;