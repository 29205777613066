import { Helmet } from 'react-helmet-async';

import { t } from '../../lib/translations';
import { default as ProductListModule } from '../../modules/algolia/ProductList.js';

import { useConfig } from '@kega/sps-core';

import useTranslatePath from '../../hooks/useTranslatePath';

const Promotions = () => {
    const config = useConfig();

    const { getPath } = useTranslatePath();

    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');

    return (
        <>
            <Helmet>
                <title>{ t('promotions.page_title') }</title>

                <link rel="canonical" href={`${baseUrl}${getPath('/promotions')}`} />

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = getPath('/promotions', {}, storeCode);
                        const lang = storeCode.split('_')[0] === 'be' ? storeCode.split('_').reverse().join('-') : storeCode.split('_').join('-');

                        return <link key={href} rel="alternate" hrefLang={ lang } href={window.location.origin + href} />
                    })
                }

            </Helmet>

            <ProductListModule 
                title={t('promotions.page_title')}
                filters={'Actie:true'}
                useAlgoliaCategoryWidget
            />

        </>
    )
}

export default Promotions;