import { useEffect, useMemo } from "react";

import classes from './BlueBillyWigVideo.module.css';
import classNames from "classnames";

const BlueBillyWigVideo = ({ videoUrl, classname = null, onClick }) => {
    const id = useMemo(() => Math.random(), []);

    useEffect(() => {

        const container = document.querySelector(`div[data-uniq-vid-id="${id}"]`);
        const existingScript = container?.querySelector(`div[data-video-embed-url="${videoUrl}"]`);

        if (existingScript) {
            return;
        }

        const script = document.createElement("script");
        script.type = "application/javascript";
        script.src = videoUrl;
        script.async = true;

        container.appendChild(script);
    }, [videoUrl]);

    return (
        <div className={classNames(classes.root, classname)} onClick={onClick}>
            <div className={classNames(classes.bbw)} data-uniq-vid-id={id} data-video-embed-url={videoUrl}></div>
        </div>
    );
}

export default BlueBillyWigVideo;