import OCC from '@kega/sps-connector-occ';

import { isScUrl } from "../lib/utils/deployment";

import OccAdyen from './api/OccAdyen';
import OccCatalog from "./api/OccCatalog";
import OccMail from './api/OccMail';
import OccRegister from './api/OccRegister';
import OccWishlist from './api/OccWishlist';
import Occ from './api/Occ';
import OccNewsletter from './api/OccNewsletter';
import TrustPilot from "./api/TrustPilot";
import OccCustomer from './api/OccCustomer';

const configureApi = (config, storeCode) => {

    const { storeViews, defaultStoreCode, authentication } = config;

    const options = {
        baseSiteId: storeViews[storeCode] ? storeViews[storeCode].baseSiteId : storeViews[defaultStoreCode].baseSiteId, 
        authentication: authentication
    };

    const occConfig = {
        ...config.occ
    };

    if (isScUrl() && config.occ.sc_url) {
        occConfig.url = config.occ.sc_url;
    }

    const { api: { cart, customer, catalog, cms, navigation, checkout }, register } = OCC(occConfig, options);

    const occadyen = register(OccAdyen);
    const occcatalog = register(OccCatalog);
    const occmail = register(OccMail);
    const occregister = register(OccRegister);
    const occwishlist = register(OccWishlist);
    const occ = register(Occ);
    const occnewsletter = register(OccNewsletter);
    const trustpilot = register(TrustPilot);
    const occcustomer = register(OccCustomer);

    return {
        cart,
        customer,
        catalog,
        cms,
        navigation,
        checkout,
        occadyen,
        occcatalog,
        occmail,
        occregister,
        occwishlist,
        occ,
        occnewsletter,
        trustpilot,
        occcustomer,
    };
}

export default configureApi;