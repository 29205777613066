import { useStorefront, useRequest } from "@kega/sps-core";

const useOrder = () => {
    const { api: { occcustomer } } = useStorefront();

    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();

    const getOrder = async (hanosOrderNumber) => {
        try {
            setRequest();

            const result = await occcustomer.getOrder({ hanosOrderNumber });

            setSuccess(result);

            return result;

        } catch (error) {
            console.log('[GetOrder]', error);
            setError(error);

            return null;
        }
    };

    return {
        invoice: data?.invoices[0],
        trackAndTraceCode: data?.trackAndTraceCodes[0],
        loading,
        error,
        getOrder
    };
};

export default useOrder;
