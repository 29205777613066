import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    categoryHierarchy: {},
    rootCategoryHierarchy: {}
};

export default createReducer(initialState, {
    [actions.addCategoryHierarchyData]: (state, action) => {
        const { payload } = action;
        const { id, data } = payload;

        state.categoryHierarchy[id] = data;
    },

    [actions.addRootCategoryHierarchyData]: (state, action) => {
        const { payload } = action;
        const { id, data } = payload;

        state.rootCategoryHierarchy[id] = data;
    }
});
