import { useEffect } from 'react';
import { useConfig } from '@kega/sps-core';

import { t } from '../../lib/translations';

import useGeoIp from "../../hooks/useGeoIp";

import Modal from '../../components/modal/Modal';
import LinkButton from '../../components/buttons/LinkButton';

import classes from './CountryNotice.module.css'

const GuestCountryNotice = ({ preventPopup }) => {
    const config = useConfig();
    const { popupShownFlag, getGeoIp, userCountry = '', setPopupShownFlag, isCountryInStoreViews } = useGeoIp();

    const storeViews = config.get('storeViews');
    const storeCode = config.get('storeCode');
    const storeviewCountry = storeViews[storeCode].country || '';

    const sameCountry = storeviewCountry?.toUpperCase() === userCountry;
    const isNetherlands = userCountry === 'NL';

    useEffect(() => {
        if (!popupShownFlag && !preventPopup) {

            if (userCountry) {
                if (!sameCountry && isCountryInStoreViews(userCountry)) {
                    setPopupShownFlag(true);
                }
            } else {
                getGeoIp();
            }
        }

    }, [])

    const handleStay = () => {
        setPopupShownFlag(false);
    };

    const handleNavigate = () => {
        const targetPath = isNetherlands ? '/nl_nl' : '/be_nl';
        window.location.href = targetPath;
        setPopupShownFlag(false);
    }

    return (
        <Modal open={popupShownFlag && !sameCountry} onClose={() => null} contentStyle={classes.modal}>
            <div className={classes.root}>
                <h2 className={classes.title}>{t('country_notice.title', { country: isNetherlands ? 'Nederland' : 'België' })}</h2>
                <div className={classes.stay_on_wrapper} onClick={handleStay}>
                    <img src={`/images/${storeviewCountry}.svg`} className={classes.flag}/>
                    <p className={classes.stay_on_text}>
                        {t('country_notice.paragraph', { country: isNetherlands ? 'Belgische' : 'Nederlandse' })}
                    </p>
                </div>
                <LinkButton 
                    className={classes.button} 
                    variant='primary'
                    onClick={handleNavigate} 
                    href={`/${isNetherlands ? 'nl_nl' : 'be_nl'}`}
                >
                    <img src={`/images/${userCountry?.toLowerCase()}.svg`} className={classes.flag} />
                    {t('country_notice.button', { country: isNetherlands ? 'Nederlandse' : 'Belgische' })}
                </LinkButton>
                
            </div>
        </Modal>
    )
 
}

export default GuestCountryNotice;