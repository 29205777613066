import { cloneElement, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import classNames from 'classnames';

import Portal from '../portal/Portal';
import useScrollLock from '../../hooks/useScrollLock';

import classes from './Modal.module.css';

const Modal = ({ children, onClose, open = false, contentStyle }, ref) => {
    
    const [isOpen, setOpen] = useState(false);
    const [closing, setClosing] = useState(false);

    const rootClassName = classNames(classes.root, {
        [classes.open]: isOpen,
        [classes.closing]: closing
    });

    const contentClassName = classNames(classes.content, contentStyle)

    useScrollLock(isOpen);

    useEffect(() => {
        if (open) {
            setOpen(open);
        } else if (isOpen) {
            setClosing(true)
        }
    }, [open])

    useImperativeHandle(ref, () => ({
        open: () => { setOpen(true); },
        close: () => { setClosing(true); }
    }));

    const onAnimationEnd = () => {
        if (closing) {
            setOpen(false);
            setClosing(false);
        }
    }

    const close = ({ target, currentTarget }) => {

        if (currentTarget === target) {
            
            if (onClose) {
                onClose();
            } else {
                setClosing(true);
            }
        }
    }
    
    if (!isOpen) { return null; }

    return (
        <Portal>
            <div className={rootClassName} onAnimationEnd={onAnimationEnd}>
                
                {/*<button type="button" onClick={close}>x</button>*/}
                
                <div className={contentClassName} onClick={close}>
                    { cloneElement(children, { onModalClose: onClose }) }
                </div>

            </div>
        </Portal>
    );
}

export default forwardRef(Modal);