import IconWrapper from '../wrapper/IconWrapper';

const EmailIcon = ({ size = 24, color = "#fff", className = null, title = "email-icon" }) => {
    return (
        <IconWrapper size={size} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke={color} strokeWidth={"2px"} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 22.1 17.1">
                <title>{title}</title>
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M19.34,1c.13,0,.27.02.4.05l-7,7.94c-.82.94-2.25,1.03-3.19.2-.07-.06-.14-.13-.2-.2L2.35,1.05c.13-.03.27-.05.41-.05h16.58Z"/>
                    <path d="M19.74,1.05c1.09.32,1.23,1.04,1.23,1.04.09.21.13.44.13.67v11.58c0,.97-.79,1.76-1.76,1.76H2.76c-.97,0-1.76-.79-1.76-1.76V2.76c0-.23.04-.45.13-.67.16-.56.64-.97,1.23-1.04"/>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default EmailIcon;