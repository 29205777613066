import { Link } from "react-router-dom";

import useTranslatePath from '../../../../hooks/useTranslatePath';

import classNames from "classnames";
import classes from './Category.module.css';

const Category = ({ name, subcategories, active, level = 2, url }) => {
    const { getPath } = useTranslatePath();

    return (
        <div className={classes.root}>
            {
                url
                    ? <Link className={classNames(classes.label, active ? classes.active : '')} to={getPath(url)}>{name}</Link>
                    : <span className={classNames(classes.label, active ? classes.active : '')}>{name}</span>
            }
            {
                subcategories.map((subcategory, index) => <Category {...subcategory} level={level + 1} key={index}/> )
            }
        </div>
    )
};

export default Category;
