export default ({ client, tokens, config }) => {

    const { url } = config;
    const apiUrl = url + '/occ/v2';

    const api = {};

    api.updateProfileField = async (updatedData) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.patch('/users/current/', updatedData, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });
    }

    api.updateAddress = async ({ addressId, updatedData }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.patch(`/hanosUsers/current/addresses/${addressId}`, updatedData, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });

    }

    api.removeAddress = async ({ addressId }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.delete(`/hanosUsers/current/addresses/${addressId}`, null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });
    }

    api.getOrders = async ({ dateFrom = '29/08/2022', dateTo, currentPage, orderCode = '', pageSize = '5', hanosOrderNumberOnly = true }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                dateFrom,
                dateTo,
                currentPage,
                orderCode,
                pageSize,
                hanosOrderNumberOnly
            }

            client.get('/hanosUsers/current/orders/search', payload, headers).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

    }

    api.getOrder = async ({ hanosOrderNumber }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.get(`/hanosUsers/current/invoices/${hanosOrderNumber}`, null, headers).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

    }

    api.getInvoiceAttachment = async ({ number, baseSiteId, country, currency }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise(async (resolve, reject) => {
            try {

                fetch(`${apiUrl}/${baseSiteId}/hanosUsers/current/invoices/${number}/pdf?lang=${country}&curr=${currency}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/pdf;charset=UTF-8',
                        'Authorization': 'Bearer ' + token
                    },
                    responseType: "arraybuffer"
                }).then((resp) => resp.blob()).then((blob) => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });

            } catch (error) {
                reject(error);
            }
        });
    };

    api.getDetails = async () => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }


            const payload = {
                fields: 'FULL'
            };

            client.get('/orgUsers/current', payload, headers).then((customer) => {
                resolve(customer);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    return api;
};
