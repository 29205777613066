import { useEffect } from "react";
import { useStoreErrors, useLogout, useClearCart, useConfig } from '@kega/sps-core';
import { useNavigate } from "react-router-dom";

import useCustomerCountry from "../../hooks/customer/useCustomerCountry";
import useTranslatePath from '../../hooks/useTranslatePath';
import usePublicToken from "../../hooks/usePublicToken";

const Errors = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const config = useConfig();
    const clearCart = useClearCart();
    const { listenError } = useStoreErrors();
    const { getPath } = useTranslatePath();
    const { getPublicToken } = usePublicToken();
    const { setCountry } = useCustomerCountry();

    useEffect(() => {

        const removeErrorListener = listenError( async ({ detail }) => {

            const { status, data = null } = detail;
            const error = data?.errors ? data.errors[0] : null;

            if (status === 401) {
                // Logout if token is expired
                logout();
                await getPublicToken();
                // navigate(getPath('/account/login'));
            } else if (error?.subjectType === 'cart' && error?.reason === 'notFound') {
                // Clear cart/checkout session if cart no longer exists
                clearCart();
            }  else if (data?.errors?.some((e) => e.code === 'ForbiddenError') &&
                        data?.errors?.some((e) => e.code === 'InvalidCountryError')) {

                const storeViews = config.get('storeViews');
                const storeCode = config.get('storeCode');
                const currentStoreCountry = storeViews[storeCode].country.toUpperCase();

                const oppositeCountry = currentStoreCountry === 'NL' ? 'BE' : 'NL';

                setCountry(oppositeCountry)
            }

        });

        return () => {
            removeErrorListener();
        }

    }, [ listenError, getPublicToken, logout, navigate, getPath, clearCart ]);

    return null;
}

export default Errors;