import { useEffect, useState, useMemo } from 'react';
import { useConfig } from '@kega/sps-core';
import { Price, Loader } from '@kega/sps-components';

import { t } from '../../../../lib/translations';

import useOrder from '../../../../hooks/customer/useOrder';
import useInvoiceAttachment from '../../../../hooks/customer/useInvoiceAttachment';

import { Col } from '../../../../components/grid';
import { ChevronIcon } from '../../../../components/icons';

import classes from './Order.module.css';

const Order = ({ hanosOrderNumber, total, placed }) => {
    const date = new Date(placed).toLocaleDateString('en-GB'); // by design it should be in the format 'dd/mm/yyyy'
    const [ open, setOpen ] = useState(false);

    const { invoice, trackAndTraceCode, getOrder, loading } = useOrder();
    const { number } = invoice || {};
    const { statusCode, trackAndTraceCode: trackCode } = trackAndTraceCode || {};

    const { getInvoiceAttachment } = useInvoiceAttachment();

    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');
    const baseSiteId = config.get('baseSiteId');
    const country = config.get('country');

    useEffect(() => {
        getOrder(hanosOrderNumber);
    }, []);

    const statusMapping = {
        pending: ['1', '2', '3', '4', '5', '6', '13', '14', '15', '16', '18', '19'],
        sent: ['7', '8', '9', '17', '20', '21'],
        delivered: ['11', '12', '22', '23', '99']
    };

    const status = useMemo(() => {
        for (const [key, values] of Object.entries(statusMapping)) {
            if (values.includes(statusCode)) {
                return key;
            }
        }
    
        return 'pending';
    }, [ statusCode ]);

    const downloadPDF = () => {
        if (!number) { return null; }

        const result = getInvoiceAttachment({ number, baseSiteId, country, currency });

        const data = new Blob([result], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${number}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
    }

    return (
        <div className={classes.container}>
            <Col md={8} className={classes.root}>
                <div className={classes.number_date}>
                    <div>
                        <span>{t('account.account_overview.banners.orders.order_number')} </span>
                        <span className={classes.order_number}>{hanosOrderNumber} </span>
                        {total?.value &&
                            <span>
                                | <Price price={total.value} locale={locale} currencySymbol={false} />
                            </span>
                        }
                    </div>
                    <span>{t('account.account_overview.banners.orders.date')} {date}</span>
                </div>
                <div>
                    {t('account.account_overview.banners.orders.status.title')}  <span className={classes[status]}>{t(`account.account_overview.banners.orders.status.${status}`)}</span>
                </div>
                <div className={classes.icon} onClick={(() => setOpen(!open))}>
                    <ChevronIcon size={11} direction={open ? 'top' : 'bottom'} />
                </div>

            </Col>

            {
                open &&
                <Col md={8} className={classes.track_root}>
                    {
                        loading ?
                            <Loader variant="primary" /> :
                            <div className={classes.track}>
                                <div>
                                    <span className={classes.track_title}>
                                        {t('account.account_overview.banners.orders.track_and_trace.title')}
                                    </span>
                                    <span className={trackCode ? classes.track_code : null}>
                                        {trackCode ?? t('account.account_overview.banners.orders.track_and_trace.not_available')}
                                    </span>
                                </div>

                                <div>
                                    <span className={classes.track_title}>
                                        {t('account.account_overview.banners.orders.invoice.title')}
                                    </span>
                                    <span onClick={downloadPDF} className={number ? classes.italic : null}>
                                        {
                                            number ?
                                                t('account.account_overview.banners.orders.invoice.download') :
                                                t('account.account_overview.banners.orders.invoice.not_available')
                                        }
                                    </span>
                                </div>
                            </div>
                    }
                </Col>
            }
        </div>
    );

}

export default Order;