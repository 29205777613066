import { AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import { connectMenu } from 'react-instantsearch-dom';

import { t } from "../../../../../lib/translations";

import { ChevronIcon } from '../../../../../components/icons';

import classes from './LevelMenu.module.css';

const LevelMenu = ({ items, refine, shouldOpen }) => {

    return (
        <AccordionItem className={classes.filter} resizeOnOpen isOpen={shouldOpen}>
            <AccordionHeader className={classes.heading}>{t('Categorieën')}<AccordionIcon color="#000" /></AccordionHeader>
            <AccordionContent>
                <div className={classes.root}>
                    <ul>
                        {
                            items.map(({ value, isRefined, label }) => (
                                <li key={value}>
                                    <a href="#" className={classes.link} style={{ fontWeight: isRefined ? 'bold' : '' }} onClick={(event) => { event.preventDefault(); refine(value); }}>
                                        <ChevronIcon direction="left" size={12} className={classes.icon} /> {label}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </AccordionContent>
        </AccordionItem>
    );

}

export default connectMenu(LevelMenu);