import { connectStateResults } from 'react-instantsearch-dom';
import { ProductImage } from '@kega/sps-components';

const Banner = ({ searchResults }) => {
    const { image = {}, link = {} } = searchResults?.renderingContent?.widgets?.banners[0] || {};
    const { urls = [], title = '' } = image || {};
    const { url } = link || {};

    if (!urls[0]?.url) {
        return null;
    }

    return (
        <>
            {
                url ?
                    <a href={url}>
                        <ProductImage src={urls[0]?.url} alt={title} />
                    </a>
                    :
                    <div>
                        <ProductImage src={urls[0]?.url} alt={title} />
                    </div>
            }
        </>
    )
}

export default connectStateResults(Banner);
