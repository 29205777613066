import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { reducers } from '@kega/sps-core';

import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import hanosCatalog from './catalog/reducer';
import hanosCheckout from './checkout/reducer';
import ui from './ui/reducer';
import cache from './cache/reducer';
import recentlyviewed from './recentlyviewed/reducer';
import user from './algolia_user/reducer';
import occ from './occ/reducer';
import wishlist from './wishlist/reducer';
import geoip from './geoip/reducer';
import register from './register/reducer';
import cookieconsent from './cookieconsent/reducer';

reducers.hanosCatalog = hanosCatalog;
reducers.hanosCheckout = hanosCheckout;
reducers.ui = ui;
reducers.recentlyviewed = recentlyviewed;
reducers.user = user;
reducers.register = register;
reducers.cookieconsent = cookieconsent;
reducers.occ = occ;
reducers.wishlist = wishlist;
reducers.geoip = geoip;
reducers.cache = persistReducer({ key: 'sp-store-cache', storage: storageSession }, cache);

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer({
    key: 'sp-store',
    version: 1,
    storage: storage,
    whitelist: ['hanosCheckout', 'customer', 'checkout', 'cart', 'recentlyviewed', 'user', 'occ', 'cookieconsent', 'geoip']
}, rootReducer);

const configureStore = () => {
    const store = createStore(persistedReducer);
    const persistor = persistStore(store);
    return { store, persistor }
}

export default configureStore;