import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRequest, useStorefront } from "@kega/sps-core";

import { addCategoryHierarchyData, addRootCategoryHierarchyData } from "../../redux/catalog/actions";

const useCategoryHierarchy = ({ categoryId = 'default' }) => {
    const { api: { occcatalog } } = useStorefront();
    const dispatch = useDispatch();

    const {
        categoryHierarchy = {},
        rootCategoryHierarchy = {}
    } = useSelector((state) => state.hanosCatalog);

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const categoryHierarchyData = categoryHierarchy[categoryId] ?? null;

    const fetchRootCategoryHierarchy = async (rootCategoryId) => {
        try {
            setRequest();

            const data = await occcatalog.getCategoryHierarchy({ rootCategoryId });
            dispatch(addRootCategoryHierarchyData({
                id: rootCategoryId,
                data: data || {}
            }));

            setSuccess();

            return data;
        } catch (error) {
            console.warn('[fetchRootCategoryHierarchy]', error);
            setError(error);

            return {};
        }
    };

    const stripSubcategories = (category) => ({
        ...category,
        subcategories: []
    })

    const parseCategory = ({ activeCategoryId, category = {}, siblings = [], level = 1, minSiblingLevel = 4 }) => {
        if (!activeCategoryId || activeCategoryId === 'default') {
            return null;
        }

        if (category.id === activeCategoryId) {
            // Active category was found
            if (level >= minSiblingLevel) {
                // Return active category with children + siblings of active category without children
                return siblings.map((sibling) => (
                    (sibling.id === activeCategoryId)
                        ? {
                            ...sibling,
                            active: true
                        } : stripSubcategories(sibling)
                ));
            } else {
                // Return active category with children
                return [{
                    ...category,
                    active: true,
                    subcategories: category.subcategories?.map(stripSubcategories) || []
                }];
            }
        }

        // Check children for active category
        let result = null;

        category?.subcategories.some((subcategory) => {
            const found = parseCategory({
                activeCategoryId,
                category: subcategory,
                siblings: category?.subcategories || [],
                level: level + 1,
                minSiblingLevel
            });

            if (found) {
                result = found;
                return true;
            }

            return false;
        });

        return result
            ? [{
                ...category,
                active: true,
                subcategories: result
            }] : null;
    };

    const buildCategoryHierarchy = async (categoryId) => {
        // 001011 = Drinks | 001012 = Non-food
        const rootCategoryId = ((categoryId !== 'default') ? categoryId?.substring(0, 6) : null) || '001012';
        const rootHierarchy = (rootCategoryHierarchy[rootCategoryId] ?? await fetchRootCategoryHierarchy(rootCategoryId)) || {};

        const hierarchy = parseCategory({
            activeCategoryId: categoryId,
            category: rootHierarchy,
            siblings: [],
            level: 1,
            minSiblingLevel: (rootCategoryId === '001011') ? 3 : 4
        });

        dispatch(addCategoryHierarchyData({
            id: categoryId,
            data: hierarchy ? (hierarchy[0] ?? null) : null
        }));
    };

    useEffect(() => {
        if (!categoryHierarchy.hasOwnProperty(categoryId)) {
            buildCategoryHierarchy(categoryId);
        }
    }, [ categoryId ]);

    return {
        error,
        loading,
        categoryHierarchy: categoryHierarchyData
    };
};

export default useCategoryHierarchy;
