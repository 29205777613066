import { useDispatch, useSelector } from 'react-redux';
import { addCustomerData } from '@kega/sps-core/src/reducers/customer/actions';

const useCustomerCountry = () => {
    const dispatch = useDispatch();

    const authenticatedUserCountry = useSelector((state) => state.customer.country);

    const setCountry = (country) => {
        dispatch(addCustomerData({ country }));
    }

    return {
        authenticatedUserCountry,
        setCountry
    }

}

export default useCustomerCountry;