import { useConfig } from '@kega/sps-core';

const fetchIndexData = async ({ indexName, params = {}, options = {} }) => {
    const config = useConfig();

    const { appId, apiKey } = config.get('algolia');

    return new Promise( async (resolve, reject) => {
        const { endpoint = 'queries', strategy = 'none', cacheTags = [] } = options;

        const indices = Array.isArray(indexName) ? indexName : [ indexName ];

        const postData = {
            requests: indices.map((index) => ({
                indexName: index,
                ...params
            })),
            strategy: strategy
        };

        const response = await fetch("https://"+appId+"-dsn.algolia.net/1/indexes/*/"+endpoint+"?x-algolia-api-key="+apiKey+"&x-algolia-application-id="+appId, {
            headers: {},
            body: JSON.stringify(postData),
            method: "POST",
            next: {
                tags: cacheTags,
                revalidate: 86400
            }
        });

        try {
            const data = await response.json();

            resolve(data);

        } catch (error) {
            reject(error);
        }

    });
};

export default fetchIndexData;