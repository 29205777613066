import { useRef, useState } from 'react';
import { useFetchCustomer, useConfig } from '@kega/sps-core';
import { Button as DefaultButton, Form } from '@kega/sps-elements';

import { t } from '../../../../lib/translations';

import EmailIcon from '../../../../components/icons/icons/EmailIcon';
import { Col, Row } from '../../../../components/grid';
import Input from '../../../../components/form/Input';

import classes from './BackInStock.module.css';
import classNames from 'classnames';

const BackInStock = ({ productCode, small = false }) => {
    const config = useConfig();
    const { customer } = useFetchCustomer();

    const [showForm, setShowForm] = useState(false);
    const [formState, setFormState] = useState({
        loading: false,
        error: false,
        success: false,
    });

    const formRef = useRef(null);

    const { loading, error, success } = formState;
    
    const clientId = config.get('emarsys').clientId;
    const baseUrl = config.get('emarsys').baseUrl;

    const isLoggedIn = customer?.authenticated && customer?.name;

    const userEmail = customer?.email || "";

    const onKeepMeUpdated = () => {
        if(isLoggedIn || success) {
            formRef.current?.submit();
        } else {
            setShowForm(true);
        }
    }

    const onSubmit = async ({ valid, values, resetForm } ) => {
        if (valid && values.inp_3) {
            const queryString = new URLSearchParams(values).toString();

            setFormState((prevState) => ({
                ...prevState,
                loading: true,
                error: false,
                success: false
            }));

            try {
                const response = await fetch(`${baseUrl}/u/register.php?${queryString}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    setFormState((prevState) => ({
                        ...prevState,
                        loading: false,
                        success: true
                    }));
                    setShowForm(false);
                    resetForm();
                }

            } catch (e) {
                setFormState((prevState) => ({
                    ...prevState,
                    loading: false,
                    error: true,
                }));
            }
        }
    }

    return (
        <Form className={classes.wrapper} onSubmit={onSubmit} ref={formRef}>
            {!showForm || success ?
                <DefaultButton className={classNames(classes.button, !small && classes.big)} onClick={onKeepMeUpdated} type="submit">
                    {
                        success ?
                            t('productdetails.back_in_stock.success_btn') :
                            !small ?
                                t('productdetails.back_in_stock.initial_btn') :
                                null
                    }
                    {
                        !success &&
                        <EmailIcon size={20} className={!small ? classes.icon : null} />
                    }
                </DefaultButton> : null
            }
            <div className={!showForm && classes.hidden_fields}>
                <Row className={classes.form}>
                    <Col md={10} className={classes.inputs}>
                        <div className={classes.hidden_fields}> 
                            <Input type="hidden" name="CID" value={clientId} />
                            <Input type="hidden" name="SID" value={""} />
                            <Input type="hidden" name="UID" value={""} />
                            <Input type="hidden" name="f" value={"781"} />
                            <Input type="hidden" name="p" value={"2"} />
                            <Input type="hidden" name="a" value={"r"} />
                            <Input type="hidden" name="el" value={""} />
                            <Input type="hidden" name="llid" value={""} />
                            <Input type="hidden" name="c" value={""} />
                            <Input type="hidden" name="counted" value={""} />
                            <Input type="hidden" name="RID" value={""} />
                            <Input type="hidden" name="mailnow" value={""} />
                            <Input type="hidden" name="inp_9456" value={productCode} />
                        </div>
                        <Input 
                            name="inp_3"
                            type="email"
                            placeholder={t('productdetails.back_in_stock.placeholder')}
                            error={error}
                            className={classes.input}
                            value={userEmail}
                        /> 
                    </Col>
                    <Col md={2} className={classes.submit}>
                        <DefaultButton className={classes.submit_button} type="submit" loading={loading}>{t('productdetails.back_in_stock.submit')}</DefaultButton>
                    </Col>
                </Row>
                { error && <p className={classes.error}>{t('productdetails.back_in_stock.error_message')}</p> } 
            </div>
        </Form>  
    );
}

export default BackInStock;