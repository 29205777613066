import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions'; 

const initialState = {
    country: null,
    popupShown: false
};

export default createReducer(initialState, {
    [actions.setCountry]: (state, action) => {
        return {
            ...state,
            country: action.payload
        };
    },
    [actions.setPopupShown]: (state, action) => {
        return {
            ...state,
            popupShown: action.payload
        };
    }
});
