import { useDispatch, useSelector } from 'react-redux';
import { useConfig } from '@kega/sps-core';

import { setCountry, setPopupShown } from '../redux/geoip/actions'; 

const useGeoIp = () => {
    const dispatch = useDispatch();
    const config = useConfig();

    const storeViews = config.get('storeViews')

    const userCountry = useSelector((state) => state.geoip.country);
    const popupShownFlag = useSelector((state) => state.geoip.popupShown);

    const getGeoIp = async () => {
        try {
            const response = await fetch('https://api.country.is/'); 
            if(response.ok) {
                const data = await response.json();
                const country = data?.country || 'Unknown'; 
                setUserCountry(country);
            }
        } catch (error) {
            console.error('Failed to fetch geo IP:', error);
        }
    };

    const setPopupShownFlag = (shown) => {
        dispatch(setPopupShown(shown));
    };

    const setUserCountry = (country) => {
        dispatch(setCountry(country));
    };

    const isCountryInStoreViews = (country) => {
        return Object.values(storeViews).some((storeview) => storeview.country?.toUpperCase() === country?.toUpperCase());
    };

    return {
        getGeoIp,
        setPopupShownFlag,
        setUserCountry,
        userCountry,
        popupShownFlag,
        isCountryInStoreViews
    };
};

export default useGeoIp;
